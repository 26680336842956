import React from 'react';
import { useRecoilValue } from 'recoil';
import { useSetRecoilState } from 'recoil';
import { navigate } from 'gatsby';
import { Button, Text, Spacing } from '@dls/web';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import {
  Heading,
  BodyText,
  ButtonWrapper,
} from '../../components/styles';
import { SuccessStatusIcon } from "../../icons/SuccessStatusIcon";
import { flowConfigState } from '../..';
import { loginBannerErrorState } from '../LoginView/utils';

const ResetSuccess = (props) => {
  const flowConfig = useRecoilValue(flowConfigState);
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  const portal = flowConfig?.portal.toLowerCase();
  const isUDF = ['udf'].includes(portal);
  const isMyportal = ['myportal'].includes(portal);
  setLoginBannerError('');
  return (
    <BoxWrapper>
      <div style={{padding: '24px 0', textAlign: 'center'}}>
        <SuccessStatusIcon />
      </div>
    <Heading>{isUDF ? 'Password changed successfully!' : 'Password reset successful'} </Heading>
    <BodyText as="div">
      <Text type="body">{isUDF ? 'You may now log in to Empower portal to access your services.' : isMyportal ? 'You can now log in to access your SingNet email.' : 'You can now log in to access your Singtel services.'}</Text>
      {isMyportal && (
        <Spacing top={3}>
        <Text type="body">Make sure to use this updated password for all platforms linked to your SingNet account.</Text>
        </Spacing>
      )}
    </BodyText>
    <div>
    <ButtonWrapper>
        <Button
          fullWidth
          secondary
          onClick={() => navigate(`/login${window.location.search}`)}
        >
          {isUDF ? 'Login to Empower' : 'Back to login'}
        </Button>
      </ButtonWrapper>
    </div>
    </BoxWrapper>
  );
};

export default ResetSuccess;
