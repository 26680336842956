import React from 'react';
import { useSetRecoilState } from 'recoil';
import { navigate } from 'gatsby';
import { Button, Text } from '@dls/web';
import {
  Heading,
  BodyText,
  ButtonWrapper,
} from '../../components/styles';
import { loginBannerErrorState } from '../LoginView/utils';

const MultipleAttempts = () => {
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  setLoginBannerError('');
  return (
    <>
    <Heading> We've received too many attempts</Heading>
    <BodyText as="div">
      <Text type="body">You may try again in 30 minutes. </Text>
    </BodyText>
    <div>
    <ButtonWrapper>
        <Button
          fullWidth
          secondary
          onClick={() => navigate(`/login${window.location.search}`)}
        >
          Back to login
        </Button>
      </ButtonWrapper>
    </div>
    </>
  );
};

export default MultipleAttempts;
