import React, { useState } from 'react';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { navigate } from 'gatsby-link';
import styled from 'styled-components';
import { InputText, Button } from '@dls/web';
import { useForm, Controller } from 'react-hook-form';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import {
  BodyText,
  ButtonWrapper,
  FieldWrapper,
  StyledLink,
  Heading
} from '../../components/styles';
import { MFARoutes } from '../../helpers/types';
import { isValidEmail, pushAnalyticsEvent } from '../../helpers/utils';
import { handleOTPErrorsToLoginPage, otpTriggerState } from '../OTPView/utils';
import Api from '../../helpers/Api';
import { SendOTPResponse } from '../../helpers/RequestResponse';
import { loginBannerErrorState, userConfigState } from '../LoginView/utils';
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';


interface Props {
  setRoute: (route: MFARoutes) => void;
}

const OTPLink = styled(StyledLink)`
  margin-top: 16px;
`;

export default function AddEmailView(props: Props) {
  const [loading, setLoading] = useState(false);
  const setOtpTrigger = useSetRecoilState(otpTriggerState);
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  const [userConfig, setUserConfig] = useRecoilState(userConfigState);

  const { control, handleSubmit, errors, setError, reset, getValues } = useForm({
    mode: 'onTouched'
  });

   const handleEmailValidation = (email) => {
    const values = getValues()
    if (!isValidEmail(email)){
      return "Enter a valid email";
    }
    return null;
   }

   const handleConfirmEmailValidation = (email) => {
    const values = getValues()
    if (values.email !== values.confirmemail) {
      return "check that your emails match";
    } else if(email.includes('@singnet')){
      return "SingNet email accounts are temporarily not supported, use another email instead"
    }
    return null;
   }

  const handleAddEmail = async (data) => {
    setLoading('inProgress');
    const email = data.email.toLowerCase();
    try {
      const response = await Api.contactAvailability({
          contactType : 'EMAIL',
          value :  email
      });
      if (response?.body) {
        if(response?.body?.status !=='FAIL') {
          const otpResponse = await Api.sendOtp({ mode: 'EMAIL', is2FA: true, contact: email});
          const success = handleOtpTrigger(otpResponse);
          if (success) {
            pushAnalyticsEvent(
              'uiam_addcontact',
              'contact_add_success',
              'Contact Added Successfully',
            );

            setUserConfig({
              ...userConfig,
              contact: email,
              userFlow: 'addEmailComplete'
            });
            props.setRoute('emailOtp')
          }
        }else {
          pushAnalyticsEvent(
            'uiam_addcontact',
            'contact_add_failure',
            'Contact Add Failure',
          );
          setError('email', { message: 'This email already exists, use another one instead' });
          setLoading(false);
        }
      } else {
        if(response?.error?.data?.code === CODE.ACCOUNT_LOCKED_TEMPORARILY){
          pushAnalyticsEvent(
            'uiam_addcontact',
            'account_temp_locked',
            'Account locked',
          );
          navigate(`/templock${window.location.search}`);
        }
        setError('email', { message: response?.error?.data?.message });
        setLoading(false);
      }
    } catch(e) {
      setLoginBannerError(ERROR.SOMETHING_WRONG);
      navigate(`/login${window.location.search}`);
    }
  }

  function handleOtpTrigger(data: SendOTPResponse) {
    const loginBannerError = handleOTPErrorsToLoginPage(data);
    if (loginBannerError !== null) {
      setLoginBannerError(loginBannerError);
      navigate(`/login${window.location.search}`);
      return false;
    } else {
      if(data?.error?.data?.code === CODE.CHALLENGE_CHECK_REQUIRED){
        setUserConfig({
          ...userConfig,
          userFlow: 'addEmailComplete'
        });
        props.setRoute('challengeNric');
        return false;
      } else {
        setOtpTrigger(data?.body);
        setUserConfig({
          ...userConfig,
          userFlow: 'addEmailComplete'
        });
        return true;
      }
    }
  }

  return (
    <BoxWrapper>
      <Heading data-testid='addMobileHeader'>Add your email</Heading>
      <BodyText type="body">
        This will also be your Onepass ID
      </BodyText>
      <form onSubmit={handleSubmit(handleAddEmail)}>
      <FieldWrapper>
      <Controller
        name="email"
        rules={{ validate: handleEmailValidation }}
        defaultValue=""
        control={control}
        render={({ value, onChange, onBlur, ref }) => (
          <InputText
            bgColor="haze"
            label="Email"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!(errors.email && errors.email.message)}
            hintMessage={errors.email && errors.email.message}
            />
          )}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Controller
        name="confirmemail"
        rules={{ validate: handleConfirmEmailValidation }}
        defaultValue=""
        control={control}
        render={({ value, onChange, onBlur, ref }) => (
        <InputText
          bgColor="haze"
          label="Confirm email"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onPaste={(e) => {
            console.log(e);
            e.preventDefault();
            setError('confirmemail', { message: 'Type in your email instead' });
        }}
          error={!!(errors.confirmemail && errors.confirmemail.message)}
          hintMessage={errors.confirmemail && errors.confirmemail.message}
        />
        )}
        />
      </FieldWrapper>
      <div>
        <ButtonWrapper>
          <Button fullWidth type="submit" loading={loading}>
            Get OTP
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
            <Button
              fullWidth
              secondary
              onClick={() => navigate(`/login${window.location.search}`)}
            >
              Cancel
            </Button>
          </ButtonWrapper>
      </div>
      </form>
    </BoxWrapper>
  );
}
