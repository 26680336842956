import React, { useState } from 'react';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { navigate } from 'gatsby-link';
import styled from 'styled-components';
import { Text, Button } from '@dls/web';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import {
  BodyText,
  ButtonWrapper,
  StyledLink,
  Heading
} from '../../components/styles';
import { MFARoutes } from '../../helpers/types';
import { handleOTPErrorsToLoginPage, otpTriggerState } from '../OTPView/utils';
import Api from '../../helpers/Api';
import { SendOTPResponse } from '../../helpers/RequestResponse';
import { loginBannerErrorState, userConfigState } from '../LoginView/utils';
import { CODE } from '../../constants/appCopy.json';


interface Props {
  setRoute: (route: MFARoutes) => void;
}

const OTPLink = styled(StyledLink)`
  margin-top: 16px;
`;

export default function VerifyEmailView(props: Props) {
  const [loading, setLoading] = useState(false);
  const setOtpTrigger = useSetRecoilState(otpTriggerState);
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  const [userConfig, setUserConfig] = useRecoilState(userConfigState);

  // function handleEmailOTP() {
  //   props.setRoute('addEmail');
  // }

  async function handleVerifyEmail() {
    setLoading('inProgress');
    const response = await Api.sendOtp({ mode: 'EMAIL', is2FA: true });
    const success = handleOtpTrigger(response);
    if (success) {
      props.setRoute('emailOtp');
    }
  }
  
  function handleOtpTrigger(data: SendOTPResponse) {
    const loginBannerError = handleOTPErrorsToLoginPage(data);
    if (loginBannerError !== null) {
      setLoginBannerError(loginBannerError);
      navigate(`/login${window.location.search}`);
      return false;
    } else {
      if(data?.error?.data?.code === CODE.CHALLENGE_CHECK_REQUIRED){
        setUserConfig({
          ...userConfig,
          userFlow: 'verifyEmailComplete'
        });
        props.setRoute('challengeNric');
        return false;
      } else {
        setOtpTrigger(data?.body);
        setUserConfig({
          ...userConfig,
          userFlow: 'verifyEmailComplete'
        });
        return true;
      }
    }
  }

  return (
    <BoxWrapper>
      <Heading data-testid='verifyEmailHeader'>Verify your email</Heading>
      <BodyText as="div">
        <Text type="body">We'll send an OTP to</Text>
        <Text type="boldBody" style={{ marginTop: '4px' }}>
            {userConfig?.contact}
        </Text>
        </BodyText>
      <BodyText type="body">
        
      </BodyText>
      <div>
        <ButtonWrapper>
          <Button fullWidth onClick={handleVerifyEmail} loading={loading}>
            Get OTP
          </Button>
        </ButtonWrapper>
      </div>
      {/* <div style={{textAlign: 'center'}}>
        <OTPLink data-testid='useEmail' onClick={handleEmailOTP}>Use another email instead</OTPLink>
      </div> */}
    </BoxWrapper>
  );
}
