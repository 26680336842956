import React, { useState, useRef } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { navigate } from 'gatsby';
import { PinInput, Text, Toast } from '@dls/web';
import { Completed } from '@dls/assets/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import Resend from '../../components/Resend';
import {
  BodyText,
  FieldWrapper,
  Heading
} from '../../components/styles';
import Api from '../../helpers/Api';
import { loginBannerErrorState } from '../LoginView/utils';
import { flowConfigState } from '../..';
import { bpvDataState} from './utils';
import { exchangeToChannel } from '../OTPView/utils';
import Spinner from '../../components/Spinner';
import SETTINGS from '../../constants/appSettings';
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';
import { generateJWE } from '../../helpers/encryption';

export default function BpvOTPView({setRoute}) {
  const [formData, setFormData] = useRecoilState(bpvDataState);
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  const [pinValue, setPinValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [timer, setTimer] = useState<boolean>(true);
  const [toast, setToast] = useState({show: false, text : ''});
  const flowConfig = useRecoilValue(flowConfigState);
  const mode = formData?.mode;
  const captchaRef = useRef();

  async function handleOTPInput(otpValue: string) {
    setError('');
    setPinValue(otpValue);
    if (otpValue.length !== 6) return;
    setLoading(true);

    try {
      const token = await captchaRef.current.executeAsync();
      const keyResponse = await Api.getKey();
      const encryptPayload = JSON.stringify({
        nric: formData?.nric,
        nricType: formData?.nricType,
        postpaidNumber: formData?.serviceNo,
        token: formData.token,
        code: otpValue
      });

      const header = { enc: 'A128GCM', alg: 'RSA-OAEP-256' };
      const jwe = await generateJWE(encryptPayload, keyResponse?.body?.keys[0], header);

      const payload = {
        referenceId: keyResponse?.body?.keys[0].kid,
        flowId: flowConfig?.flowId,
        data: jwe,
        reCaptchaToken: token
      };

    const verifyResponse = await Api.verifyOtpForBPV(payload);
    
    if(verifyResponse?.body) {
      setToast({
        show: true,
        text: 'Verification has been successful'
      });
      exchangeToChannel();
    } else {
      if(verifyResponse?.error?.data?.code === 'OTP_INVALID'){
        setError(ERROR.INVALID_OTP);
      } else if(verifyResponse?.error?.data?.code === 'OTP_EXPIRED'){
        setError(ERROR.EXPIRED_OTP);
      } else if(verifyResponse?.error?.data?.code === 'OTP_BLOCKED'){
        setLoginBannerError('Maximum tries reached for OTP verification. Try again in 30 minutes.');
        navigate(`/login${window.location.search}`);
      } else {
        setLoginBannerError(ERROR.SOMETHING_WRONG);
        navigate(`/login${window.location.search}`);
      }
      setLoading(false);
      setPinValue('');
    }
  } catch (e) {
    setError(ERROR.SOMETHING_WRONG);
  } finally {
    setLoading(false);
  }
}
  async function handleSend() {
    const token = await captchaRef.current.executeAsync();
    try {
      const keyResponse = await Api.getKey();
      const encryptPayload = JSON.stringify({
        nric: formData?.nric,
        nricType: formData?.nricType,
        serviceNumber: formData?.contact
      });
      // encrypt data
      const header = { enc: 'A128GCM', alg: 'RSA-OAEP-256' };
      const jwe = await generateJWE(encryptPayload, keyResponse?.body?.keys[0], header);
      const payload = {
        referenceId: keyResponse?.body?.keys[0].kid,
        flowId: flowConfig?.flowId,
        data: jwe,
        reCaptchaToken: token
      };

      const response = await Api.bpverifyAccount(payload);
      if (response?.body) {
        const  { sendOtpResult } = response?.body;
        setFormData({
          ...formData,
          token: sendOtpResult?.token,
        });
      } else {
          setLoginBannerError(ERROR.SOMETHING_WRONG);
          navigate(`/login${window.location.search}`);
      }
      return true;
    } catch (e) {
      setLoginBannerError(ERROR.SOMETHING_WRONG);
      navigate(`/login${window.location.search}`);
    }
  }

  async function handleResend() {
    setSpinner(true);
    const success = await handleSend();
    if (success) {
      setPinValue('');
      setSpinner(false);
      setToast({
          show: true,
          text: mode === 'SMS' ? COPY.otp.sms.sentcopy : COPY.otp.email.sentcopy
        });
    }
    return success;
  }

  const countView: React.ReactNode = null;
  let title: string, masked: string, link: string, resendPreText: string;
  if (mode == 'SMS') {
    title = COPY.otp.sms.title;
    masked = formData?.contactForDisplay;
    link = COPY.otp.sms.link;
    resendPreText = COPY.otp.sms.bodycopy;
  } else {
    title = COPY.otp.email.title;
    masked = formData?.contactForDisplay;
    link = COPY.otp.email.link;
    resendPreText = COPY.otp.email.bodycopy;
  }

  return (
    <BoxWrapper>
      {spinner ?  <Spinner/> : null }
      <>
        <Heading data-testid={mode === 'SMS' ? 'smsOtp' : 'emailOtp'}>{title}</Heading>
        <div style={{overflowWrap: 'break-word'}}>
          <BodyText as="div">
            <Text type="body">{COPY.otp.bodycopy}</Text>
            <Text type="boldBody" style={{ marginTop: '4px' }}>
              {masked}
            </Text>
          </BodyText>
        </div>
        <FieldWrapper style={{ display: 'flex', justifyContent: 'center' }}>
          <PinInput
            style={{ display: 'inline-block' }}
            onChange={handleOTPInput}
            error={!!error}
            message={error}
            value={pinValue}
            loading={loading}
            size={6}
            autoFocus
            bgColor="haze"
            digitsOnly
          />
        </FieldWrapper>
        {loading ? (
          <BodyText as="div" style={{'margin': '0px', 'padding': '24px 0 0'}}>
            <Text type="body">{mode === 'SMS' ? COPY.otp.sms.verifycopy : COPY.otp.email.verifycopy}</Text>
          </BodyText>
          ) : (
            <>
              <Resend onClick={handleResend} timer={timer} preText={resendPreText} />
            </>
          )}
        <Toast
          visible={toast.show}
          text={toast.text}
          icon={Completed}
          onClose={() => setToast({show: false, text: ''})}
          action={{
            text: 'Close',
            onPress: () => setToast({show: false, text: ''})
          }}
        />
        <ReCAPTCHA
          ref={captchaRef}
          size="invisible"
          sitekey={SETTINGS.CAPTCHA_SITE_KEY}
        />
      </>
    </BoxWrapper>
  );
}
