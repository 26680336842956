import React from 'react';
import { Button } from '@dls/web';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import {
  Heading,
  StyledLink,
  ButtonWrapper,
  BodyText
} from '../../components/styles';

export default function StayLoggedInView() {
  return (
    <BoxWrapper>
      <Heading>Stay logged in?</Heading>
      <BodyText type="boldBody">
        This will reduce number of times you are asked to login in again on this
        device.
      </BodyText>
      <ButtonWrapper>
        <Button fullWidth>Yes</Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <Button fullWidth secondary>
          No
        </Button>
      </ButtonWrapper>
      <StyledLink type="link" href="https://example.com" target="_blank">
        Don't show again
      </StyledLink>
    </BoxWrapper>
  );
}
