import React, { useState } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { navigate } from 'gatsby';
import { InputText, Button, Text, Modal } from '@dls/web';
import ReCAPTCHA from 'react-google-recaptcha';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import {
  Heading,
  BodyText,
  ButtonWrapper,
  FieldWrapper,
  ModalText,
} from '../../components/styles';
import {
  formatUsername,
  formatUsernameforBackend,
  validateUsername
} from '../../helpers/utils';
import Api from '../../helpers/Api';
import { resetOtpTriggerState } from '../OTPView/utils';
import { flowConfigState } from '../..';
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';
import SETTINGS from '../../constants/appSettings'


const AccountLocked = (props) => {
  const { modal } = props.location.state || false;
  const [notifyModal, setNotifyModal] = useState<any>(modal);
  const setResetOTP = useSetRecoilState(resetOtpTriggerState);
  const [loading, setLoading] = useState<any>(false);
  const [resetId, setResetId] = useState('');
  const [error, setError] = useState('');
  const flowConfig = useRecoilValue(flowConfigState);
  const portal = flowConfig?.portal.toLowerCase();
  const recaptchaRef = React.useRef();

  const handleOnchange = (data) => {
    setResetId(formatUsername(data));
    const isError = validateUsername(data);
    setError(isError!==true ? isError : '');
  }

  const handleReset = async () => {
    setLoading('inProgress');
    const token = await recaptchaRef.current.executeAsync();
    const userId = formatUsernameforBackend(resetId, portal);
    try {
      const payload = { 
        flowId: flowConfig?.flowId,
        userId,
        reCaptchaToken: token
      };
      const response  = await Api.sendOtpForReset(payload);
      if(response?.body) {
        setResetOTP({...response?.body, resetId});
        props.setRoute('resetPasswordOtp');
      } else {
        if(response?.metadata?.status?.toLowerCase() === 'missing captcha token' || response?.error?.data?.message?.toLowerCase() === 'invalid interaction'){
          setError('Try again later. Your device or network may be sending automated queries. To protect our users, we can\'t process your request right now.');
        } else if(response?.error?.data?.code === CODE.USER_ACCOUNT_TEMP_LOCKED){
          setError(ERROR.USER_ACCOUNT_TEMP_LOCKED);
        } else if(response?.error?.data?.code === 'CONTACT_NOT_FOUND_FOR_OTP')  {
          setError(ERROR.FURTHER_ASSISTANCE);
        } else {
          setError('Enter a valid OnePass ID');
        }
        setLoading(false);
      }
    } catch(e) {
      setError(ERROR.SOMETHING_WRONG);
      setLoading(false);
    }
  }

  return (
    <BoxWrapper>
    <Heading>Your account has been locked</Heading>
    <BodyText as="div">
      <Text type="body">For your security, verify your OnePass ID.</Text>
    </BodyText>
    <FieldWrapper>
      <InputText
        autoFocus
        bgColor="haze"
        label="Email or mobile number"
        value={resetId}
        onChange={(e: any) => handleOnchange(e.target.value)}
        error={!!error}
        hintMessage={error}
      />
    </FieldWrapper>
    <div>
      <ButtonWrapper>
        <Button fullWidth onClick={handleReset} loading={loading}>
          {COPY.addmobile.cta_getotp}
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <Button
          fullWidth
          secondary
          onClick={() => navigate(`/login${window.location.search}`)}
        >
          {'Cancel'}
        </Button>
      </ButtonWrapper>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={SETTINGS.CAPTCHA_SITE_KEY}
      />
    </div>
    <Modal
        visible={notifyModal}
        onClose={() => { setNotifyModal(false);}}
        title={'Update your account'}
      >
        <Modal.Content style={{width: '460px'}}>
          <ModalText type="body">
            We’ve recently updated our security policies. For enhanced security, reset your password to continue. <Text type="link" inline onMouseDown={() => window.open('https://www.singtel.com/personal/support/singnet#accountupdate', '_blank').focus()}>Learn more</Text>
          </ModalText>
          <div>
            <ButtonWrapper>
              <Button fullWidth onClick={() => setNotifyModal(false)}>
                OK, got it
              </Button>
          </ButtonWrapper>
        </div>
        </Modal.Content>
      </Modal>
    </BoxWrapper>
  );
};

export default AccountLocked;
