import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { navigate } from 'gatsby-link';
import styled from 'styled-components';
import { Notification, InputPassword, Button } from '@dls/web';
import { useForm, Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import PasswordCheckList from './checklist';
import {
  BodyText,
  ButtonWrapper,
  FieldWrapper,
  StyledLink,
  Heading
} from '../../components/styles';
import { MFARoutes } from '../../helpers/types';
import {
  formatUsernameforBackend
} from '../../helpers/utils';
import { flowConfigState } from '../..';
import { resetOtpTriggerState, otpVerifyState } from '../OTPView/utils';
import Api from '../../helpers/Api';
import { ERROR } from '../../constants/appCopy.json';
import { generateJWE } from '../../helpers/encryption';
import SETTINGS from '../../constants/appSettings'

interface Props {
  setRoute: (route: MFARoutes) => void;
}

const OTPLink = styled(StyledLink)`
  margin-top: 16px;
`;

export default function ResetPasswordView(props: Props) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const flowConfig = useRecoilValue(flowConfigState);
  const otpTrigger = useRecoilValue(resetOtpTriggerState);
  const otpVerify = useRecoilValue(otpVerifyState);
  const portal = flowConfig?.portal.toLowerCase();
  const userId = otpTrigger?.resetId;
  const captchaRef = React.useRef();
  const isUDF = ['udf'].includes(portal);
  const isMyportal = ['myportal'].includes(portal);

  const { watch, control, handleSubmit, errors, setError, getValues } = useForm({
    mode: 'onTouched'
  });

  const checkPassword = watch("password");

  const updateStatus = (data) => {
    console.log(data);
    setStatus(data);
  }

  const handlePasswordValidation = (data) => {
  if (!data){
    return "Enter a new password first";
  }
  return null;
  }

  const handleConfirmPasswordValidation = (data) => {
  const values = getValues();
  if (values.password !== values.confirmpassword) {
    return "check that your passwords match";
  }
  return null;
  }

  const handleResetPassword = async (data) => {
    if(!status) return;
    setLoading('inProgress');
    const token = await captchaRef.current.executeAsync();
    const user = formatUsernameforBackend(userId, portal);
    const keyResponse = await Api.getPublicKey();
    const encryptPayload = JSON.stringify({
        userId: user.toLowerCase(),
        password: data.password
    });

    // Step 2: encrypt data
    const header = { enc: 'A128GCM', alg: 'RSA-OAEP-256' };
    const jwe = await generateJWE(encryptPayload, keyResponse?.body?.keys[0], header);

    // Step 3: do login
    const resetPayload = {
      authCode: otpVerify?.authCode,
      referenceId: keyResponse?.body?.keys[0].kid,
      flowId: flowConfig?.flowId,
      data: jwe,
      reCaptchaToken: token
    };
    
    const resetResponse = await Api.resetPwd(resetPayload);
    if(resetResponse?.body) {
      props.setRoute('resetSuccess');
    } else {
      if(resetResponse?.metadata?.status?.toLowerCase() === 'missing captcha token' || resetResponse?.error?.data?.message?.toLowerCase() === 'invalid interaction'){
        setError('password', { message: 'Try again later. Your device or network may be sending automated queries. To protect our users, we can\'t process your request right now.' });
      } else if(resetResponse?.error?.data?.code === 'RESET_PASSWORD_REPEATED'){
        setError('password', { message: 'Enter a password different from the passwords used previously' });
      } else if(resetResponse?.error?.data?.code === 'RESET_PASSWORD_FAILED') {
        setError('password', { message: ERROR.SOMETHING_WRONG });
      } else {
        setError('password', { message: ERROR.SOMETHING_WRONG })
      }
      setLoading(false);
    }
  }

  return (
    <BoxWrapper>
      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        sitekey={SETTINGS.CAPTCHA_SITE_KEY}
      />
      {(otpTrigger?.notify && !isUDF) ? (
        <div data-testid="notification" style={{ marginBottom: '16px' }}>
          <Notification
            type="alert"
            content={'Your account has been locked. For security reasons, reset your password to continue.'}
          />
        </div>
      ) : null}
      <Heading>{isUDF? 'Set new password' : isMyportal ? 'Reset your SingNet password' : 'Reset password'} </Heading>
      <BodyText type="body">
        {isMyportal ? 'To better protect your account from unauthorised access, create a new password.' : 'Keep your account safe with a strong password.'}
      </BodyText>
      <form onSubmit={handleSubmit(handleResetPassword)}>
      <FieldWrapper>
      <Controller
        name="password"
        rules={{ validate: handlePasswordValidation }}
        defaultValue=""
        control={control}
        render={({ value, onChange, onBlur }) => (
          <InputPassword
            bgColor="haze"
            label="New password"
            value={value}
            onChange={onChange}
            onBlur={(e) => {}}
            error={!!(errors.password && errors.password.message)}
            hintMessage={errors.password && errors.password.message}
            />
          )}
        />
      </FieldWrapper>
      <PasswordCheckList value={checkPassword} updateStatus={updateStatus}/>
      <FieldWrapper>
        <Controller
        name="confirmpassword"
        rules={{ validate: handleConfirmPasswordValidation }}
        defaultValue=""
        control={control}
        render={({ value, onChange, onBlur }) => (
        <InputPassword
          bgColor="haze"
          label="Confirm password"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onPaste={(e) => {
            console.log(e);
            e.preventDefault();
            setError('confirmpassword', { message: 'Type in your password instead' });
          }}
          error={!!(errors.confirmpassword && errors.confirmpassword.message)}
          hintMessage={errors.confirmpassword && errors.confirmpassword.message}
        />
        )}
        />
      </FieldWrapper>
      <div>
        <ButtonWrapper>
          <Button fullWidth type="submit" loading={loading}>
            Confirm
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
            <Button
              fullWidth
              secondary
              onClick={() => navigate(`/login${window.location.search}`)}
            >
              Cancel
            </Button>
          </ButtonWrapper>
      </div>
      </form>
    </BoxWrapper>
  );
}
