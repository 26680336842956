import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import AddEmailView from '../views/AddEmailView';
import VerifyEmailView from '../views/VerifyEmailView';
import AddMobileView from '../views/AddMobileView';
import ResetPasswordView from '../views/ResetPasswordView';
import ResetSuccess from '../views/ResetPasswordView/success';
import AccountLocked from '../views/ResetPasswordView/locked';
import ChallengeView from '../views/ChallengeView';
import OTPView from '../views/OTPView';
import ResetOTPView from '../views/ResetPasswordView/otp';
import StayLoggedInView from '../views/StayLoggedInView';
import Footer from '../components/Footer';
import { MFARoutes } from '../helpers/types';
import PageLayout from '../components/Layout/PageLayout';
import { navigate, PageProps } from 'gatsby';
import BpVerifyNric from '../views/BpvView/verifyNric';
import BpVerifyAccount from '../views/BpvView/verifyAccount';
import BpvOTPView from '../views/BpvView/otp';
import MultipleAttempts from '../views/BpvView/mutipleAttempts';

interface MFAState {
  initialRoute?: MFARoutes;
}

interface Challenge {
  verified: boolean;
  prevRoute: MFARoutes;
}

export default function Mfa(props: PageProps<object, object, MFAState>) {
  let { initialRoute } = props.location.state || {};
  let [route, _setRoute] = useState<MFARoutes>(null);
  let challenge = useRef<Challenge>({ verified: false, prevRoute: null });

  useEffect(() => {
    initialRoute ? setRoute(initialRoute) : navigate(`/login${window.location.search}`);
  }, []);

  function setRoute(page: MFARoutes) {
    let isChallengeVerified = challenge.current.verified;
    if (['challengeNric'].includes(page) && !isChallengeVerified) {
      page = 'challengeNric';
    }
    _setRoute(page);
  }

  function updateChallengeStatus(status: boolean, page: MFARoutes) {
    challenge.current.verified = status;
    _setRoute(page);
  }

  let currentview: React.ReactNode = null;
  switch (route) {
    case 'addMobile':
      currentview = <AddMobileView setRoute={setRoute} location={props.location} />;
      break;
    case 'addEmail':
      currentview = <AddEmailView setRoute={setRoute} />;
      break;
    case 'verifyEmail':
      currentview = <VerifyEmailView setRoute={setRoute} />;
      break;
    case 'smsOtp':
      currentview = <OTPView setRoute={setRoute} mode="SMS" location={props.location} />;
      break;
    case 'emailOtp':
      currentview = <OTPView setRoute={setRoute} mode="EMAIL" location={props.location} />;
      break;
    case 'resetPasswordOtp':
      currentview = <ResetOTPView setRoute={setRoute} />;
      break;
    case 'resetPassword':
      currentview = <ResetPasswordView setRoute={setRoute} />;
      break;
    case 'accountresetLocked':
      currentview = <AccountLocked setRoute={setRoute} location={props.location} />;
      break;
    case 'resetSuccess':
      currentview = <ResetSuccess />;
      break;
    case 'bpvNric':
      currentview = <BpVerifyNric setRoute={setRoute} />;
      break;
    case 'bpvAccount':
      currentview = <BpVerifyAccount setRoute={setRoute} />;
      break;
    case 'bpvOtp':
      currentview = <BpvOTPView setRoute={setRoute} />;
      break;
    case 'mutipleAttempts':
      currentview = <MultipleAttempts />;
      break;
    case 'stayLoggedIn':
      currentview = <StayLoggedInView />;
      break;
    case 'challengeNric':
      currentview = (
        <ChallengeView updateChallengeStatus={updateChallengeStatus} />
      );
  }

  return (
    <PageLayout>
      <Header />
      {currentview}
      <Footer />
    </PageLayout>
  );
}
