import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { PinInput, Text, Toast } from '@dls/web';
import { Completed } from '@dls/assets/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import Resend from '../../components/Resend';
import {
  BodyText,
  StyledLink,
  FieldWrapper,
  Heading
} from '../../components/styles';
import { MFARoutes } from '../../helpers/types';
import Api from '../../helpers/Api';
import {
  resetOtpTriggerState,
  otpVerifyState
} from '../OTPView/utils';
import { loginBannerErrorState } from '../LoginView/utils';
import { flowConfigState } from '../..';
import media from '../../helpers/media';
import Spinner from '../../components/Spinner';
import SETTINGS from '../../constants/appSettings';
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';

interface Props {
  setRoute: (route: MFARoutes) => void;
}

const OTPLink = styled(StyledLink)`
  margin-top: 24px;
  ${media.sm`
    margin-top: 32px;
  `}
`;

export default function ResetOTPView(props: Props) {
  const [otpTrigger, setOtpTrigger] = useRecoilState(resetOtpTriggerState);
  const setOtpVerify = useSetRecoilState(otpVerifyState);
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  const [pinValue, setPinValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [timer, setTimer] = useState<boolean>(true);
  const [toast, setToast] = useState({show: false, text : ''});
  const flowConfig = useRecoilValue(flowConfigState);
  const mode = otpTrigger?.mode;
  const userId = otpTrigger?.resetId;
  const isGE = flowConfig?.client === 'GE';
  const captchaRef = React.useRef();

  async function handleOTPInput(otpValue: string) {
    setError('');
    setPinValue(otpValue);
    
    if (otpValue.length !== 6) return;
    setLoading(true);

    // step 1: verify otp
    const payload = {
      authCode: otpTrigger?.authCode,
      challengeToken: otpTrigger?.challengeToken,
      flowId: flowConfig?.flowId,
      otp: otpValue
    }
  
    console.log(otpTrigger);
    const verifyResponse = await Api.verifyOtpForReset(payload);
    
    if(verifyResponse?.body) {
      setOtpVerify(verifyResponse?.body);
      props.setRoute('resetPassword');
    } else {
      if(verifyResponse?.error?.data?.code === CODE.VERIFY_OTP_FAILED_INVALID){
        setError(ERROR.INVALID_OTP);
      } else if(verifyResponse?.error?.data?.code === CODE.VERIFY_OTP_FAILED_EXPIRED){
        setError(ERROR.EXPIRED_OTP);
      } else if(verifyResponse?.error?.data?.code === CODE.VERIFY_OTP_FAILED_BLOCKED){
        setLoginBannerError(ERROR.MAXIMUM_TRIES_REACHED);
        navigate(`/login${window.location.search}`);
      } else if(verifyResponse?.error?.data?.code === CODE.USER_ACCOUNT_TEMP_LOCKED){
        setLoginBannerError(ERROR.USER_ACCOUNT_TEMP_LOCKED);
        navigate(`/login${window.location.search}`);
      } else {
        setLoginBannerError(ERROR.SOMETHING_WRONG);
        navigate(`/login${window.location.search}`);
      }
      setLoading(false);
      setPinValue('');
    }
  }

  async function handleSend() {
    const token = await captchaRef.current.executeAsync();
    const payload = { 
      flowId: flowConfig?.flowId,
      userId,
      reCaptchaToken: token
    };
    try {
      const otpResponse = await Api.sendOtpForReset(payload);
      setOtpTrigger({...otpResponse?.body, resetId: userId});
      return true;
    } catch (e) {
      setLoginBannerError(ERROR.SOMETHING_WRONG);
      navigate(`/login${window.location.search}`);
    }
  }

  async function handleResend() {
    setSpinner(true);
    const success = await handleSend();
    if (success) {
      setPinValue('');
      setSpinner(false);
      setToast({
          show: true,
          text: mode === 'SMS' ? COPY.otp.sms.sentcopy : COPY.otp.email.sentcopy
        });
    }
    return success;
  }

  // async function handleChangeMode() {
  //   const newOtpMode: OtpMode = props.mode === 'SMS' ? 'EMAIL' : 'SMS';
  //   const success = await handleSend();
  //   if (!success) return;
  //   setTimer(false);
  //   props.setRoute('resetPasswordOtp');
  //   setTimer(() => true);
  // }

  const countView: React.ReactNode = null;

  let title: string, masked: string, link: string, resendPreText: string;
  if (mode == 'SMS') {
    title = COPY.otp.sms.title;
    masked = otpTrigger?.contact;
    link = COPY.otp.sms.link;
    resendPreText = COPY.otp.sms.bodycopy;
  } else {
    title = COPY.otp.email.title;
    masked = otpTrigger?.contact;
    link = COPY.otp.email.link;
    resendPreText = COPY.otp.email.bodycopy;
  }

  return (
    <>
      {spinner ?  <Spinner/> : null }
      <BoxWrapper>
        <Heading data-testid={mode === 'SMS' ? 'smsOtp' : 'emailOtp'}>{title}</Heading>
        <div style={{overflowWrap: 'break-word'}}>
          <BodyText as="div">
            <Text type="body">{COPY.otp.bodycopy}</Text>
            <Text type="boldBody" style={{ marginTop: '4px' }}>
              {masked}
            </Text>
          </BodyText>
        </div>
        <FieldWrapper style={{ display: 'flex', justifyContent: 'center' }}>
          <PinInput
            style={{ display: 'inline-block' }}
            onChange={handleOTPInput}
            error={!!error}
            message={error}
            value={pinValue}
            loading={loading}
            size={6}
            autoFocus
            bgColor="haze"
            digitsOnly
          />
        </FieldWrapper>
        {loading ? (
          <BodyText as="div" style={{'margin': '0px', 'padding': '24px 0 0'}}>
            <Text type="body">{mode === 'SMS' ? COPY.otp.sms.verifycopy : COPY.otp.email.verifycopy}</Text>
          </BodyText>
          ) : (
            <>
              <Resend onClick={handleResend} timer={timer} preText={resendPreText} />
              {/* {!isGE && <div style={{textAlign: 'center'}}><OTPLink data-testid={props.mode === 'SMS' ? 'preferEmail' : 'preferSms'} onClick={handleChangeMode}>{link}</OTPLink></div>} */}
            </>
          )}
        <Toast
          visible={toast.show}
          text={toast.text}
          icon={Completed}
          onClose={() => setToast({show: false, text: ''})}
          action={{
            text: 'Close',
            onPress: () => setToast({show: false, text: ''})
          }}
        />
        <ReCAPTCHA
          ref={captchaRef}
          size="invisible"
          sitekey={SETTINGS.CAPTCHA_SITE_KEY}
        />
      </BoxWrapper>
    </>
  );
}
