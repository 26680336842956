import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { PinInput, Text, Toast } from '@dls/web';
import { Completed } from '@dls/assets/icons';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import Resend from '../../components/Resend';
import {
  BodyText,
  StyledLink,
  FieldWrapper,
  Heading
} from '../../components/styles';
import { MFARoutes, OtpMode } from '../../helpers/types';
import Api from '../../helpers/Api';
import {
  handleOTPErrorsToLoginPage,
  otpTriggerState,
  otpVerifyState,
  exchangeToken
} from './utils';
import { loginBannerErrorState, userConfigState } from '../LoginView/utils';
import { flowConfigState } from '../..';
import media from '../../helpers/media';
import Spinner from '../../components/Spinner';
import SETTINGS from '../../constants/appSettings';
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';
import { pushAnalyticsEvent } from '../../helpers/utils';

interface Props {
  setRoute: (route: MFARoutes) => void;
  mode: OtpMode;
}

const OTPLink = styled(StyledLink)`
  margin-top: 24px;
  ${media.sm`
    margin-top: 32px;
  `}
`;
let skipVerify = true;
let is2FA = false;

export default function OTPView(props: Props) {
  const { skip } = props.location.state || {};
  const [otpTrigger, setOtpTrigger] = useRecoilState(otpTriggerState);
  const setOtpVerify = useSetRecoilState(otpVerifyState);
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  const [userConfig, setUserConfig] = useRecoilState(userConfigState);
  const [pinValue, setPinValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [timer, setTimer] = useState<boolean>(true);
  const [toast, setToast] = useState({show: false, text : ''});
  const flowConfig = useRecoilValue(flowConfigState);
  const portal = flowConfig?.portal.toLowerCase();
  const blockOption = ['udf'].includes(portal);

  async function handleOTPInput(otpValue: string) {
    setError('');
    setPinValue(otpValue);
    
    if (otpValue.length !== 6) return;
    setLoading(true);

    // step 1: verify otp
    const referenceId = otpTrigger?.referenceId;
    const verifyResponse = await Api.verifyOtp({ referenceId, otpValue });
    setOtpVerify(verifyResponse);

    if(verifyResponse?.body) {
      is2FA = true;
      if(userConfig.userFlow === 'verifyEmail' && props.mode  === 'EMAIL') {
        skipVerify = false;
      }
      if(userConfig.isPostLogin  && (!userConfig.userFlow.includes('Complete') && skipVerify)) {
        props.setRoute(userConfig.userFlow); 
        return;
      }
      pushAnalyticsEvent(
        'uiam_otp',
        'otp_verify_success',
        'OTP Verify Success',
      );

      exchangeToken(verifyResponse, flowConfig, userConfig, setLoginBannerError, props.setRoute, setToast);

    } else {
      if(verifyResponse?.error?.data?.code === CODE.VERIFY_OTP_FAILED_INVALID){
        setError(ERROR.INVALID_OTP);
      }
      if(verifyResponse?.error?.data?.code === CODE.VERIFY_OTP_FAILED_EXPIRED){
        setError(ERROR.EXPIRED_OTP);
      }
      if(verifyResponse?.error?.data?.code === CODE.VERIFY_OTP_FAILED_BLOCKED){
        setLoginBannerError(ERROR.MAXIMUM_TRIES_REACHED);
        navigate(`/login${window.location.search}`);
      }
      if(verifyResponse?.error?.data?.code === CODE.USER_ACCOUNT_LOCKED){
        setLoginBannerError(ERROR.USER_ACCOUNT_LOCKED);
        navigate(`/login${window.location.search}`);
      }
      if(verifyResponse?.error?.data?.code === CODE.USER_ACCOUNT_TEMP_LOCKED){
        setLoginBannerError(ERROR.USER_ACCOUNT_TEMP_LOCKED);
        navigate(`/login${window.location.search}`);
      }
      setLoading(false);
      setPinValue('');
      pushAnalyticsEvent(
        'uiam_otp',
        'otp_verify_failure',
        'OTP Verify Failed',
      );
    }
  }

  async function handleSend(mode: OtpMode) {
    try {
      let payload = {
        mode
      }
      if(userConfig.isPostLogin && is2FA) { payload = { ...payload, is2FA: true, contact: userConfig?.contact }}
      const otpResponse = await Api.sendOtp(payload);
      const loginBannerError = handleOTPErrorsToLoginPage(otpResponse);
      if (loginBannerError !== null) {
        if(loginBannerError === CODE.USER_ACCOUNT_TEMP_LOCKED) {
          navigate(`/templock${window.location.search}&maxlimit=true`);
        } else if(loginBannerError === CODE.USER_ACCOUNT_LOCKED) {
          setLoginBannerError(ERROR.USER_ACCOUNT_LOCKED);
          navigate(`/login${window.location.search}`);
        } else {
          setLoginBannerError(loginBannerError);
          navigate(`/login${window.location.search}`);
        }
        pushAnalyticsEvent(
          'uiam_otp',
          `otp_${mode.toLowerCase()}_sent_failure`,
          `${mode} OTP Sent Failed`,
        );
        return false;
      } else {
        if(otpResponse?.error?.data?.code === CODE.CHALLENGE_CHECK_REQUIRED){
          setUserConfig({
            ...userConfig,
            userFlow: 'challengeNric'
          });
          props.setRoute('challengeNric');
          return false;
        } else {
          setError('');
          setOtpTrigger(otpResponse?.body);
          pushAnalyticsEvent(
            'uiam_otp',
            `otp_${mode.toLowerCase()}_sent_success`,
            `${mode} OTP Sent Success`,
          );
          return true;
        }
      }
    } catch (e) {
      setLoginBannerError(ERROR.SOMETHING_WRONG);
      navigate(`/login${window.location.search}`);
    }
  }

  async function handleResend() {
    setSpinner(true);
    const success = await handleSend(props.mode);
    if (success) {
      setPinValue('');
      setSpinner(false);
      setToast({
          show: true,
          text: props.mode === 'SMS' ? COPY.otp.sms.sentcopy : COPY.otp.email.sentcopy
        });
    }
    return success;
  }

  async function handleChangeMode() {
    const newOtpMode: OtpMode = props.mode === 'SMS' ? 'EMAIL' : 'SMS';
    const success = await handleSend(newOtpMode);
    if (!success) return;
    setTimer(false);
    setPinValue('');
    props.setRoute(props.mode === 'SMS' ? 'emailOtp' : 'smsOtp');
    setTimer(() => true);
  }

  const countView: React.ReactNode = null;

  let title: string, masked: string, link: string, resendPreText: string;
  if (props.mode == 'SMS') {
    title = COPY.otp.sms.title;
    masked = otpTrigger.contact;
    link = COPY.otp.sms.link;
    resendPreText = COPY.otp.sms.bodycopy;
  } else {
    title = COPY.otp.email.title;
    masked = otpTrigger.contact;
    link = COPY.otp.email.link;
    resendPreText = COPY.otp.email.bodycopy;
  }

  return (
    <>
    {spinner ? <Spinner/> : null }
      <BoxWrapper>
        <Heading data-testid={props.mode === 'SMS' ? 'smsOtp' : 'emailOtp'}>{title}</Heading>
        <BodyText as="div">
          <Text type="body">{COPY.otp.bodycopy}</Text>
          <Text type="boldBody" style={{ marginTop: '4px' }}>
            {masked}
          </Text>
        </BodyText>
        <FieldWrapper style={{ display: 'flex', justifyContent: 'center' }}>
          <PinInput
            onChange={handleOTPInput}
            error={!!error}
            message={error}
            value={pinValue}
            loading={loading}
            size={6}
            autoFocus
            bgColor="haze"
            digitsOnly
          />
        </FieldWrapper>
        {loading ? (
          <BodyText as="div">
            <Text type="body">{props.mode === 'SMS' ? COPY.otp.sms.verifycopy : COPY.otp.email.verifycopy}</Text>
          </BodyText>
          ) : (
            <>
              <Resend onClick={handleResend} timer={timer} preText={resendPreText} />
              {countView}
              {!skip && (
                !blockOption && !userConfig.isPostLogin ? <div style={{textAlign: 'center'}}><OTPLink data-testid={props.mode === 'SMS' ? 'preferEmail' : 'preferSms'} onClick={handleChangeMode}>{link}</OTPLink></div> : null 
              )}
            </>
          )}
        <Toast
          visible={toast.show}
          text={toast.text}
          icon={Completed}
          onClose={() => setToast({show: false, text: ''})}
          action={{
            text: 'Close',
            onPress: () => setToast({show: false, text: ''})
          }}
        />
      </BoxWrapper>
    </>
  );
}
