import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm, Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { InputText, Button, DropdownInput, Toast } from '@dls/web';
import { Completed } from '@dls/assets/icons';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import {
  BodyText,
  ButtonWrapper,
  FieldWrapper,
  Heading
} from '../../components/styles';
import { flowConfigState } from '../..';
import { bpvDataState} from './utils';
import { generateJWE } from '../../helpers/encryption';
import Api from '../../helpers/Api';
import SETTINGS from '../../constants/appSettings';
import { loginBannerErrorState } from '../LoginView/utils';
import { exchangeToChannel } from '../OTPView/utils';
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';

export default function BpVerifyNric({setRoute}) {
  const captchaRef = useRef();
  const flowConfig = useRecoilValue(flowConfigState);
  const [formData, setFormData] = useRecoilState(bpvDataState);
  const setBannerError = useSetRecoilState(loginBannerErrorState);
  const [loading, setLoading] = useState(false);
  const [showNricType, setShowNricType] = useState(false);
  const [toast, setToast] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    setError,
    errors
  } = useForm({
    mode: 'onTouched'
  });

  const nricValue = watch("nric");

  const handleNRICValidation = (nric) => {
    const regex = /^[STFGM]\d{7}[A-Z]$/;
    if (!nric) return "Please provide your NRIC/FIN";
    if (!regex.test(nric)) return "Invalid NRIC/FIN";
    return true;
  };

  const handleNRICTypeValidation = (nricType) => {
    if ((nricValue?.startsWith('F') || nricValue?.startsWith('G') || nricValue?.startsWith('M')) && !nricType) {
      return "Please select your document type";
    }
    return true;
  };

  const handleNRIC = async (data) => {
    setLoading('inProgress');
    try {
      const token = await captchaRef.current.executeAsync();
      const keyResponse = await Api.getKey();
      const encryptPayload = JSON.stringify({
        nric: data.nric,
        nricType: data.nricType || "CPR"
      });

      const header = { enc: 'A128GCM', alg: 'RSA-OAEP-256' };
      const jwe = await generateJWE(encryptPayload, keyResponse.body.keys[0], header);

      const payload = {
        referenceId: keyResponse.body.keys[0].kid,
        flowId: flowConfig.flowId,
        data: jwe,
        reCaptchaToken: token
      };

      const response = await Api.bpverifyNRIC(payload);
      if (response?.body) {
        const { status } = response?.body;
        if(status === 'DONE'){
          setToast(true);
          exchangeToChannel();
        } else {
          setFormData({
            ...formData,
            nric: data.nric,
            nricType: data.nricType || "CPR"
          });
          setRoute('bpvAccount');
        }
      } else {
        const errorCode = response?.error?.data?.code;
        const errorMessages = {
          'INVALID_CUST_ID': 'We could not proceed with your request. Please provide a valid NRIC/FIN',
          'CUST_ID_ALREADY_EXISTS': 'We could not proceed with your request. Please provide a valid NRIC/FIN',
          'INVALID_RECAPTCHA': 'Invalid reCAPTCHA',
          default: ERROR.SOMETHING_WRONG
        };
        setError('nric', { message: errorMessages[errorCode] || errorMessages.default });
      }
    } catch (e) {
      setError('nric', { message: ERROR.SOMETHING_WRONG});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (nricValue && (nricValue.startsWith('F') || nricValue.startsWith('G') || nricValue.startsWith('M'))) {
      setShowNricType(true);
    } else {
      setShowNricType(false);
    }
  }, [nricValue, setValue]);

  return (
    <BoxWrapper>
      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        sitekey={SETTINGS.CAPTCHA_SITE_KEY}
      />
      <Heading>Verify account</Heading>
      <BodyText type="body">
        Enter the NRIC/FIN used to register your Singtel services. 
      </BodyText>
      <form onSubmit={handleSubmit(handleNRIC)}>
        <FieldWrapper>
          <Controller
            name="nric"
            control={control}
            defaultValue=""
            rules={{ validate: handleNRICValidation }}
            render={({ value, onChange, onBlur }) => (
              <InputText
                bgColor="haze"
                label="NRIC/FIN"
                maxLength="9"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setValue('nric', e.target.value.toUpperCase());
                }}
                onBlur={onBlur}
                error={!!errors.nric}
                hintMessage={errors.nric?.message}
              />
            )}
          />
        </FieldWrapper>
        {showNricType && (
          <FieldWrapper>
            <Controller
              name="nricType"
              control={control}
              defaultValue=""
              rules={{ validate: handleNRICTypeValidation }}
              render={({ onChange, onBlur }) => (
                <DropdownInput
                  bgColor="haze"
                  label="Document Type"
                  items={[
                    { text: "Employment Pass", value: "EP" },
                    { text: "Work Permit", value: "WP" },
                    { text: "Social Visit Pass", value: "SVP" },
                    { text: "Foreigner Student Pass", value: "STP" },
                    { text: "Foreigner S Pass", value: "SP" },
                    { text: "Dependent Pass", value: "DP" },
                    { text: "Diplomatic", value: "DM" },
                    { text: "Non-diplomatic", value: "NDM" }
                  ]}
                  onChange={(e) => {
                    onChange(e);
                    setValue('nricType', e.value);
                  }}
                  onBlur={onBlur}
                  error={!!errors.nricType}
                  hintMessage={errors.nricType?.message}
                />
              )}
            />
          </FieldWrapper>
        )}
        <div>
          <ButtonWrapper>
            <Button fullWidth type="submit" loading={loading}>
              Continue
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button fullWidth secondary onClick={() => navigate(`/login${window.location.search}`)}>
              Cancel
            </Button>
          </ButtonWrapper>
        </div>
      </form>
      <Toast
        visible={toast}
        text={'Verification has been successful'}
        icon={Completed}
        onClose={() => setToast(false)}
        action={{
          text: 'Close',
          onPress: () => setToast(false)
        }}
      />
    </BoxWrapper>
  );
}
