import React, { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { navigate } from 'gatsby-link';
import { InputText, Button } from '@dls/web';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import {
  BodyText,
  ButtonWrapper,
  FieldWrapper,
  Heading
} from '../../components/styles';
import { MFARoutes } from '../../helpers/types';
import Api from '../../helpers/Api';
import {
  ChallengeDetail,
  VerifyChallengeRequest,
  SendOTPResponse
} from '../../helpers/RequestResponse';
import { loginBannerErrorState, userConfigState } from '../LoginView/utils';
import { handleOTPErrorsToLoginPage, otpTriggerState } from '../OTPView/utils';
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';
import { pushAnalyticsEvent } from '../../helpers/utils';

interface Props {
  updateChallengeStatus: (status: boolean, route: MFARoutes) => void;
  initialState?: ChallengeDetail;
}

export default function ChallengeView(props: Props) {
  const [challenge, setChallenge] = useState<ChallengeDetail>(
    props.initialState
  );
  const [nricvalue, setNricValue] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const setOtpTrigger = useSetRecoilState(otpTriggerState);
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  const [userConfig, setUserConfig] = useRecoilState(userConfigState);

  useEffect(() => {
    // get challenge
    Api.getChallenge().then((res) => {
      setChallenge(res?.body?.challengeDetails[0]);
    })
    .catch((e) => {
      setLoginBannerError(ERROR.SOMETHING_WRONG);
      navigate(`/login${window.location.search}`);
    });
  }, []);

  async function handleEmailOTP() {
    const response = await Api.sendOtp({ mode: 'EMAIL'});
    const success = handleOtpTrigger(response);
    if (success) props.updateChallengeStatus(true, 'emailOtp');
  }

  async function handleConfirm() {
    const validExp = new RegExp(challenge.validaterRegEx);
    if (nricvalue.match(validExp)) {
      setError(null);
      setLoading('inProgress');
      
      //verify challenge
      let payload: VerifyChallengeRequest = {
        challengeDetails: [{ id: challenge.id, value: nricvalue.toUpperCase() }]
      };

      try {
        const response = await Api.verifyChallenge(payload);
        if(response?.body) {
          pushAnalyticsEvent(
            'uiam_challenge',
            'challenge_verify_success',
            'Challenge Verify Success',
          );
          Api._setToken(response?.body?.accessToken); // 2FA
          if(userConfig.userFlow === 'addMobile') {
            props.updateChallengeStatus(true, 'addMobile');
          } else if(userConfig.userFlow === 'verifyNric') {
            setUserConfig({
              ...userConfig,
              userFlow: 'addMobile',
            });
            handleEmailOTP();
          } else {
            handleEmailOTP();
          }
        } else {
          if(response?.error?.data?.code === CODE.INVALID_CHALLENGE_INPUT){
            if(response?.error?.data?.count > 2){
              setError(ERROR.INVALID_CHALLENGE_ID);
            }
            if(response?.error?.data?.count === 2){
              setError(ERROR.CHALLENGE_LEFT_2_TRY);
            }
            if(response?.error?.data?.count === 1){
              setError(ERROR.CHALLENGE_LEFT_1_TRY);
            }
          }
          if(response?.error?.data?.code === CODE.USER_ACCOUNT_LOCKED){
            setLoginBannerError(ERROR.USER_ACCOUNT_LOCKED);
            navigate(`/login${window.location.search}`);
          }
          setLoading(false);
          pushAnalyticsEvent(
            'uiam_challenge',
            'challenge_verify_failure',
            'Challenge Verify Failure',
          );
        }
      } catch (e) {
        setLoginBannerError(ERROR.SOMETHING_WRONG);
        navigate(`/login${window.location.search}`);
      }
    } else {
      setError(ERROR.INVALID_NRIC);
      setLoading(false);
    }
  }

  function handleOtpTrigger(data: SendOTPResponse) {
    const loginBannerError = handleOTPErrorsToLoginPage(data);
    if (loginBannerError !== null) {
      setLoginBannerError(loginBannerError);
      navigate(`/login${window.location.search}`);
      return false;
    } else {
      setOtpTrigger(data?.body);
      return true;
    }
  }

  return (
    <BoxWrapper>
      {challenge ? (
        <>
          <Heading data-testid="nricHeader">{challenge.fieldText}</Heading>
          <BodyText type="body">
            {COPY.challenge.bodycopy}
          </BodyText>
          <FieldWrapper>
            <InputText
              autoFocus
              maxLength="5"
              bgColor="haze"
              label={challenge.helpText}
              type="text"
              value={nricvalue}
              onChange={(e: any) => setNricValue((e.target.value).toUpperCase())}
              hintMessage={error}
              error={!!error}
            />
          </FieldWrapper>
          <div>
            <ButtonWrapper>
              <Button fullWidth onClick={handleConfirm} loading={loading}>
                {COPY.challenge.cta_confirm}
              </Button>
            </ButtonWrapper>
          </div>
        </>
      ) : null}
    </BoxWrapper>
  );
}
