import { atom } from 'recoil';

export const bpvDataState = atom({
  key: 'bpvDataState',
  default: {
    nric: '',
    nricType: '',
    serviceNo: '',
    serviceType: '',
    mode: '',
    contact: '',
    contactForDisplay: '',
    token: '',
  },
});