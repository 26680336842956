import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { navigate } from 'gatsby-link';
import styled from 'styled-components';
import { Text, InputText, Button } from '@dls/web';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import {
  BodyText,
  ButtonWrapper,
  FieldWrapper,
  StyledLink,
  Heading
} from '../../components/styles';
import { MFARoutes } from '../../helpers/types';
import {
  formatUsernameforBackend,
  pushAnalyticsEvent
} from '../../helpers/utils';
import {
  handleOTPErrorsToLoginPage,
  otpTriggerState,
  otpVerifyState,
  exchangeToken
} from '../OTPView/utils';
import Api from '../../helpers/Api';
import { SendOTPResponse } from '../../helpers/RequestResponse';
import { flowConfigState } from '../..';
import { loginBannerErrorState, userConfigState } from '../LoginView/utils';
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';
import Spinner from '../../components/Spinner';

interface Props {
  setRoute: (route: MFARoutes) => void;
}

const OTPLink = styled(StyledLink)`
  margin-top: 16px;
`;

export function validateMobile(mobile: string) {
  if (!/^65\-\d{8}$/.test(mobile)) {
    return ERROR.INVALID_NUMBER;
  } else if (!mobile.startsWith('65-8') && !mobile.startsWith('65-9')) {
    return ERROR.INVALID_START;
  }
  return null;
}

export default function AddMobileView(props: Props) {
  const { skip } = props.location.state || {};
  const [mobile, setMobile] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [skiploading, setSkipLoading] = useState(false);
  const [touched, setTouched] = useState(false);
  const setOtpTrigger = useSetRecoilState(otpTriggerState);
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  const otpVerify = useRecoilValue(otpVerifyState);
  const flowConfig = useRecoilValue(flowConfigState);
  const portal = flowConfig?.portal.toLowerCase();
  const isMyportal = ['myportal','uiam'].includes(portal);
  const [userConfig, setUserConfig] = useRecoilState(userConfigState);

  useEffect(() => {
    if (touched) {
      const value = formatUsernameforBackend(mobile);
      const isError = validateMobile(value);
      setError(isError);
    }
  }, [touched, mobile]);

  function handleSkip() {
    setSkipLoading('inProgress');
    setUserConfig({
      ...userConfig,
      isPostLogin: false,
      userFlow: 'addMobileComplete'
    });
    exchangeToken(
      otpVerify,
      flowConfig,
      userConfig,
      setLoginBannerError,
      props.setRoute
    );
  }

  async function handleAddMobile() {
    setTouched(true);
    const value = formatUsernameforBackend(mobile);
    const isError = validateMobile(value);
    setError(isError);
    if (isError) return;
    setLoading('inProgress');
    try {
      const response = await Api.contactAvailability({
        contactType: 'MOBILE',
        value: value
      });
      if (response?.body) {
        if (response?.body?.status !== 'FAIL') {
          const otpResponse = await Api.sendOtp({
            mode: 'SMS',
            contact: value
          });
          const success = handleOtpTrigger(otpResponse);
          if (success) {
            pushAnalyticsEvent(
              'uiam_addcontact',
              'contact_add_success',
              'Contact Added Successfully'
            );
            setUserConfig({
              ...userConfig,
              contact: value,
              userFlow: 'addMobileComplete'
            });
            props.setRoute('smsOtp');
          }
        } else {
          pushAnalyticsEvent(
            'uiam_addcontact',
            'contact_add_failure',
            'Contact Add Failure'
          );
          setError(ERROR.NUMBER_ALREADY_INUSE);
          setLoading(false);
        }
      } else {
        if (response?.error?.data?.code === CODE.ACCOUNT_LOCKED_TEMPORARILY) {
          pushAnalyticsEvent(
            'uiam_addcontact',
            'account_temp_locked',
            'Account locked'
          );
          navigate(`/templock${window.location.search}`);
        }
        setError(response?.error?.data?.message);
        setLoading(false);
      }
    } catch (e) {
      setLoginBannerError(ERROR.SOMETHING_WRONG);
      navigate(`/login${window.location.search}`);
    }
  }

  function handleOtpTrigger(data: SendOTPResponse) {
    const loginBannerError = handleOTPErrorsToLoginPage(data);
    if (loginBannerError !== null) {
      setLoginBannerError(loginBannerError);
      navigate(`/login${window.location.search}`);
      return false;
    } else {
      if (data?.error?.data?.code === CODE.CHALLENGE_CHECK_REQUIRED) {
        setUserConfig({
          ...userConfig,
          userFlow: 'addMobileComplete'
        });
        props.setRoute('challengeNric');
        return false;
      } else {
        setOtpTrigger(data?.body);
        setUserConfig({
          ...userConfig,
          userFlow: 'addMobileComplete'
        });
        return true;
      }
    }
  }

  return (
    <>
      {skiploading ? <Spinner /> : null}
      <BoxWrapper>
        <Heading data-testid="addMobileHeader">
          {COPY.addmobile.pagetitle}
        </Heading>
        {isMyportal ? (
          <BodyText>
            For enhanced security, update your contact details to continue.{' '}
            <Text
              type="link"
              inline
              onMouseDown={() =>
                window
                  .open(
                    'https://www.singtel.com/personal/support/onepass',
                    '_blank'
                  )
                  .focus()
              }
            >
              {' '}
              Learn more
            </Text>
          </BodyText>
        ) : (
          <BodyText type="body">{COPY.addmobile.bodycopy}</BodyText>
        )}
        <FieldWrapper>
          <InputText
            autoFocus
            displayFormat="mobile-sg"
            bgColor="haze"
            label={COPY.addmobile.field_add.lable}
            onChange={(e: any) => setMobile(e.target.value)}
            // onBlur={() => setTouched(true)}
            error={!!error}
            hintMessage={error}
          />
        </FieldWrapper>
        <div>
          <ButtonWrapper>
            <Button fullWidth onClick={handleAddMobile} loading={loading}>
              {COPY.addmobile.cta_getotp}
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              fullWidth
              secondary
              onClick={() => navigate(`/login${window.location.search}`)}
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </div>
      </BoxWrapper>
    </>
  );
}
